@import "tailwindcss";

@theme {
  --font-nunito: "Nunito", "sans-serif";
  --font-nunito-italic: "Nunito Italic", "sans-serif";
  --font-nunito-sans: "NunitoSans", "sans-serif";
  --font-nunito-sans-italic: "NunitoSans Italic", "sans-serif";

  --radius-whispy-l: 100rem;
  --radius-whispy-m: 1rem;
  --radius-whispy-s: 0.5rem;
}

@layer base {
  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 100 900;
    src: url("../assets/fonts/Nunito.ttf") format("truetype");
  }

  @font-face {
    font-family: "Nunito Italic";
    font-style: italic;
    font-weight: 100 900;
    src: url("../assets/fonts/Nunito-Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "NunitoSans";
    font-style: normal;
    font-weight: 100 900;
    src: url("../assets/fonts/NunitoSans.ttf") format("truetype");
  }

  @font-face {
    font-family: "NunitoSans Italic";
    font-style: italic;
    font-weight: 100 900;
    src: url("../assets/fonts/NunitoSans-Italic.ttf") format("truetype");
  }

  h1 {
    @apply font-nunito text-3xl lg:text-5xl;
  }

  h2 {
    @apply font-nunito text-2xl lg:text-4xl;
  }

  h3 {
    @apply font-nunito text-base lg:text-2xl;
  }

  p {
    @apply font-nunito-sans;
  }

  button {
    @apply cursor-pointer;
  }
}

@layer components {
  .success-message {
    @apply mb-2 rounded-md border border-green-700 bg-green-800 px-3 py-1 font-semibold text-gray-100 transition-opacity duration-1000;
  }

  .error-message {
    @apply mb-2 rounded-md border border-red-900 bg-red-800 px-3 py-1 font-semibold text-gray-100;
  }
}

@utility whispy-button {
  @apply px-6 py-3 font-nunito text-xl font-semibold transition-colors lg:px-8 lg:py-6 lg:text-4xl;
}

@utility whispy-primary-button {
  @apply border border-blue-500 bg-blue-500 whispy-button text-white;
  &:hover {
    @apply bg-white text-blue-500;
  }
}

@utility whispy-secondary-button {
  @apply border border-gray-600 whispy-button;
  &:hover {
    @apply bg-gray-200;
  }
}
